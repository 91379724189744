import BotUI from 'botui';
import Timer from 'easytimer.js';
import './style/style.scss';
import './style/botui-theme-xebia.scss';
import {getFirstStep, getNextStep} from './bot.service';

const botui = new BotUI('bot');
const delay = 1000;
const hintQueue = [];

function startTimer() {
  const timer = new Timer();
  timer.start();
  timer.addEventListener('secondsUpdated', e => {
    document.getElementById('timer').innerHTML = timer.getTimeValues().toString();
  });
}

startTimer();

botui.message.add({
  loading: true,
}).then(index => {
  start(index);
});

const elevator = document.getElementsByClassName('botui botui-container');

function start(index) {
  return getFirstStep()
    .then(({data}) => process(index, data))
    .catch(() => sayUnknownMsg(index));
}

function showVideo(video) {
  return setTimeout(() => {
    return botui.message.add({
      type: 'embed',
      content: video,
    });
  }, delay);
}

function process(index, data) {
  let messageUpdated = false;
  if (data.next) {
    data.next.forEach(n => next(n));
  }
  if (data.message) {
    say(index, data.message);
    messageUpdated = true;
  }
  if (data.buttons) {
    showButtons(data.buttons);
  }
  if (data.input) {
    showInput(data.input);
  }
  if (data.video) {
    showVideo(data.video);
  }
  if (!messageUpdated) {
    return botui.message.remove(index);
  }
  setTimeout(() => {
    elevator.scrollTop = elevator.scrollHeight;
  }, 1000);
}

function showInput(input) {
  return setTimeout(() => {
    return botui.action.text({
      action: {
        placeholder: input.placeholder,
      },
    }).then(({value}) => {
      // noinspection JSUnresolvedFunction
      ga('send', 'event', {eventCategory: 'Code', eventAction: 'Enter', eventLabel: value});
      return next({id: value, delay}, input, true);
    });
  }, delay);
}

function showButtons(buttons) {
  return setTimeout(() => {
    return botui.action.button({
      action: buttons,
    }).then(({value}) => {
      ga('send', 'event', {eventCategory: 'Button', eventAction: 'Press', eventLabel: value});
      next({id: value});
    });
  }, delay);
}

function sayUnknownMsg(index) {
  return botui.message.update(index, {
    loading: false,
    content: `Désolé, je n'ai pas compris 🤔`
  });
}

function say(index, message) {
  return botui.message.update(index, {
    loading: false,
    content: message,
  });
}

function clearHintQueue() {
  hintQueue.forEach(clearTimeout);
  hintQueue.length = 0;
}

function next(next, input, isUserInput) {
  hintQueue.push(setTimeout(() => {
    return botui.message.add({
      loading: true,
    }).then(index => {
      return getNextStep(next.id)
        .then(({data}) => {
          if (isUserInput) {
            clearHintQueue();
          }
          process(index, data);
        })
        .catch(() => {
          if (input) {
            say(index, input.error);
            showInput(input);
          } else {
            sayUnknownMsg(index);
          }
        });
    });
  }, next.delay));
}
